/* You can add global styles to this file, and also import other style files */

/***************************
****************************
Reset
****************************
***************************/
/* Change all elements to use border-box */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* my styles */
body {
  font-family: Calibri;
  padding-bottom: 20px;
  max-width: 1280px;
  background-color: #FAFAFA
  /* light of dark theme 50 */
}

/* form */
div mat-card {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  margin: 10px;
}

.ng-valid[required], .ng-valid.required  {
  border-left: 5px solid #42A948; /* green */
}

/* guide says .ng-invalid:not(form) */
input.ng-invalid.ng-dirty  {
  border-left: 5px solid #a94442; /* red */
}

/* flex layout */
flex-container {
  display: flex;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

p {
  margin-top: 0;
  margin-bottom: 0.5em;
}

mat-card-header.mat-card-header {
  margin-bottom: 16px;
  /*
   * shouldn't be necessary because already in .mat-card-header
   * but doesn't work on mobile without this
   */

}
