/*
 * custom theme
 * angular-theme.scss
 */
@use '@angular/material' as mat;

// Angular Material 2.0.0-beta.3
//   deep imports will no longer work
// @import '@angular/material/core/theming/all-theme';
// @import '@angular/material/theming';

// Include non-theme styles for core.
@include mat.core();

// Define a theme.
$primary: mat.define-palette(mat.$indigo-palette, 400, 100, 900);
$accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
$warn:    mat.define-palette(mat.$red-palette);

$theme: mat.define-light-theme($primary, $accent, $warn);

// Include all theme styles for the components.
@include mat.all-component-themes($theme);

.md-primary {
  background-color: map-get($primary, default);
  color: map-get($primary, default-contrast);
}

.md-primary.md-hue-1, button.mat-primary.md-hue-1 {
  background-color: map-get($primary, lighter);
  color: map-get($primary, lighter-contrast);
}

.md-primary.md-hue-2, button.mat-primary.md-hue-2 {
  background-color: map-get($primary, darker);
  color: map-get($primary, darker-contrast);
}

.md-accent {
  background-color: map-get($accent, default);
  color: map-get($accent, default-contrast);
}

.md-accent.md-hue-1 {
  background-color: map-get($accent, lighter);
  color: map-get($accent, lighter-contrast);
}

.md-accent.md-hue-2 {
  background-color: map-get($accent, darker);
  color: map-get($accent, darker-contrast);
}

.md-warn {
  background-color: map-get($warn, default);
  color: map-get($warn, default-contrast);
}

//
// 700 grey is for the dark strips outside the 1280 sceen width
// color is #616161
//
body {
 background-color: map-get(mat.$grey-palette, 700);
}

.mat-grey-appbar {
  background-color: map-get(mat.$grey-palette, 100);
}

.my-text-primary {
  color: rgba(map-get($primary, default), 0.87);
}

.my-text-primary.secondary {
  color: rgba(map-get($primary, default), 0.54);
}

.my-text-primary.disabled {
  color: rgba(map-get($primary, default), 0.38);
}

.my-text-primary.md-hue-1 {
  color: rgba(map-get($primary, lighter), 0.87);
}

.my-text-primary.md-hue-2 {
  color: rgba(map-get($primary, darker), 0.87);
}

.my-text-accent {
  color: rgba(map-get($accent, default),0.87);
}

.my-text-accent.md-hue-1 {
  color: map-get($accent, lighter);
}

.my-text-accent.md-hue-2 {
  color: map-get($accent, darker);
}

.my-text-warn {
  color: map-get($warn, default);
}

// stop using the deprecated table border attribute
table.border1 {
  border-collapse: collapse;
  &, & tr, & th, & td {
    border: 1px solid black;
  }
}
